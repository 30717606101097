import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import ConstMenu from '../../constants/menu';
import StateConsumer from '../../helpers/ContextState';
import Store from '../../helpers/Store';

let modulos

class Sidebar extends Component {
  static contextType = StateConsumer;

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    //this.constante_menu()
  }

  // constante_menu=()=>{
  //   if (  Store.get("user").privilegios!=='' &&
  //         Store.get("user").privilegios!==undefined &&
  //         Store.get("user").privilegios.modulos!==undefined &&
  //         (
  //           parseInt(Store.get("user").privilegios.tipo_usuario_id)!==1 &&
  //           parseInt(Store.get("user").privilegios.tipo_usuario_id)!==27
  //         )
  //       ){
  //         let modulos   =   (Store.get("user").privilegios.modulos!=='')?JSON.parse(Store.get("user").privilegios.modulos):[]
  //
  //         modulos.map((row,key)=>{
  //           Object.entries(row).map((row2,key2)=>{
  //             let modulo  =   row2[0];
  //             console.log(ConstMenu[modulo],ConstMenu);
  //           })
  //           //console.log(item);
  //           //console.log(ConstMenu,row);
  //         })
  //
  //         //console.log(modulos);
  //
  //   }else if(   Store.get("user").privilegios!=='' &&
  //               Store.get("user").privilegios!==undefined &&
  //               Store.get("user").privilegios.modulos!==undefined &&
  //         (
  //           parseInt(Store.get("user").privilegios.tipo_usuario_id)===1 ||
  //           parseInt(Store.get("user").privilegios.tipo_usuario_id)===27
  //         )
  //       ){
  //     console.log(Store.get("user").privilegios.tipo_usuario_id)
  //   }else {
  //
  //   }
  //
  //   //console.log(Store.get("user").privilegios.modulos);
  // }

  menuCompleto=()=>{
    //console.log('menu completo');
    return ConstMenu.map((row,k)=>{
      return    <li key={k} className={ this.isPathActive(row.path) ? 'nav-item active' : 'nav-item' }>
                  {
                    (row.collapse===undefined || row.collapse==="")?
                        <Link className="nav-link" to={row.path}>
                          {(row.ico!==undefined && row.ico!=="")?row.ico:<i className="mdi mdi-television menu-icon"></i>}
                          <span className="menu-title">{row.label}</span>
                        </Link>
                      : <>
                          <div className={ this.state[row.collapse.in] ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState(row.collapse.in) } data-toggle="collapse">
                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                            <span className="menu-title">{row.label}</span>
                            <i className="menu-arrow"></i>
                          </div>

                          <Collapse in={ this.state[row.collapse.in] }>
                            <ul className="nav flex-column sub-menu">
                              {row.collapse.items.map((row2,k2)=>{
                                if (row2.label!=="Balance") {
                                  return  <li  key={k2}
                                              className="nav-item">
                                              <Link className={ this.isPathActive(row2.path) ? 'nav-link active' : 'nav-link' } to={row2.path}>
                                                {row2.label}
                                              </Link>
                                          </li>
                                }else {
                                  return  <>
                                          </>
                                }
                              })}
                            </ul>
                          </Collapse>
                        </>
                  }
                </li>

    })

  }

  menuDiscriminado=()=>{
   //console.log(modulos);
    return ConstMenu.map((row,k)=>{
      if (modulos[row.path]!==undefined && modulos[row.path]===true) {
        //console.log(modulos[row.path])
        return    <li key={k} className={ this.isPathActive(row.path) ? 'nav-item active' : 'nav-item' }>
                    {
                      (row.collapse===undefined || row.collapse==="")?
                          <Link className="nav-link" to={row.path}>
                            {(row.ico!==undefined && row.ico!=="")?row.ico:<i className="mdi mdi-television menu-icon"></i>}
                            <span className="menu-title">{row.label}</span>
                          </Link>
                        : <>
                            <div className={ this.state[row.collapse.in] ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState(row.collapse.in) } data-toggle="collapse">
                               {row.ico}
                              <span className="menu-title">{row.label}</span>
                              <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={ this.state[row.collapse.in] }>
                              <ul className="nav flex-column sub-menu">
                                {row.collapse.items.map((row2,k2)=>{
                                  if (modulos[row2.path]!==undefined && modulos[row2.path]===true) {
                                  return  <li  key={k2}
                                              className="nav-item">
                                              <Link className={ this.isPathActive(row2.path) ? 'nav-link active' : 'nav-link' } to={row2.path}>
                                                {row2.label}
                                              </Link>
                                          </li>
                                  }else {
                                    return <></>
                                  }
                                })}
                              </ul>
                            </Collapse>
                          </>
                    }
                  </li>
                  
                  
      }else {
          return <></>
      }
    })
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/Clientes', state: 'basicUiMenuOpen'},
      {path:'/Configuracion', state: 'basicUiMenuOpen2'},
      {path:'/Productos', state: 'basicUiMenuOpen3'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
  }

  render () {
    //console.log('provilegios: ', Store.get("user").privilegios.tipo_usuario_id);
    if (  Store.get("user").privilegios!=='' &&
          Store.get("user").privilegios!==undefined &&
          Store.get("user").privilegios.modulos!==undefined &&
          (
            parseInt(Store.get("user").privilegios.tipo_usuario_id)!==1 &&
            parseInt(Store.get("user").privilegios.tipo_usuario_id)!==27
          )
        ){
        modulos   =   (Store.get("user").privilegios.modulos!=='')?JSON.parse(Store.get("user").privilegios.modulos):[]
    }else {
      modulos     =   "menuCompleto"
    }
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html"><img src={require("../../assets/images/design/header-logo.svg")} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src={require("../../assets/images/design/header-logo.svg" )} alt="logo" /></a>
        </div>
         <div className="nav-link">
            <Dropdown>
                <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                <div className="profile-image">
                      {<img src={(this.context.state.user.miImg===undefined || this.context.state.user.miImg==="" || this.context.state.user.miImg==="null" || this.context.state.user.miImg===null)? require("../../assets/images/faces-clipart/pic-1.png"):this.context.state.user.miImg} alt="profile" />}
                    </div>
                   <br />
                  <div className="">
                   
                    <div className="ml-3">
                      <p className="profile-name text-center">{this.context.state.user.nombres} {this.context.state.user.apellidos}</p>
                      <small className="designation text-muted text-small text-center">{(this.context.state.user.privilegios!==undefined)?this.context.state.user.privilegios.tipo:"Cargando..."}</small>
                      <span className="status-indicator online"></span>
                     
                    </div>
                   
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="d-flex">
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                        <i className="mdi mdi-account-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-alarm-check mr-0"></i>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    Manage Accounts
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                  Change Password
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    Check Inbox
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={()=>{this.context.cerrarSesion()}}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
          <ul className="nav">
          <li className="nav-item nav-profile not-navigation-link">
                   <h4 className="text-center" >Mi cuenta</h4>
          </li>
          {(modulos!=='' && modulos==='menuCompleto')?this.menuCompleto():this.menuDiscriminado()}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);
