import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route,Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import StateConsumer from '../helpers/ContextState';
import {Store} from '../helpers/Index';
import queryString from 'query-string';

const queryStringParams = queryString.parse(window.location.search);
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Wishlist = lazy(() => import('./wishlist/Wishlist'));
const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));
const Confirm = lazy(() => import('./user-pages/Confirm'));
const RecoverPassword = lazy(() => import('./user-pages/RecoverPassword'));
const Pagos = lazy(() => import('./clientes/Pagos'));
const Listado = lazy(() => import('./clientes/Listado'));
const TiposUsuarios = lazy(() => import('./clientes/TiposUsuarios'));
const Productos = lazy(() => import('./configuracion/Productos'));
const Categorias = lazy(() => import('./configuracion/Categorias'));
const Marcas = lazy(() => import('./configuracion/Marcas'));
const Backup = lazy(() => import('./configuracion/Backup'));
const ProductosPrecios = lazy(() => import('./productos/Precios'));
const ProductosInventario = lazy(() => import('./productos/ProductosInventario'));
const Carrito = lazy(() => import('./productos/Carrito'));
const CarritoAdmin = lazy(() => import('./productos/CarritoAdmin'));
const Pago = lazy(() => import('./productos/Pago'));
const PedidosRealizados = lazy(() => import('./productos/PedidosRealizados'));
const PedidosRealizadosEnvios = lazy(() => import('./productos/PedidosRealizadosEnvios'));
const BalancePedidosRealizados = lazy(() => import('./productos/BalancePedidosRealizados'));
const LinksGenerados = lazy(() => import('./productos/LinksGenerados'));
const LinksGeneradosPayU = lazy(() => import('./productos/LinksGeneradosPayU'));
const LinksGeneradosPayUAdd = lazy(() => import('./productos/LinksGeneradosPayUAdd'));
const LinksGeneradosAdd = lazy(() => import('./productos/LinksGeneradosAdd'));
const DetalleCompra = lazy(() => import('./productos/DetalleCompra'));
const GeneraLink = lazy(() => import('./productos/GeneraLink'));
const ComisionPayU = lazy(() => import('./productos/ComisionPayU'));
const PagosVendedores = lazy(() => import('./productos/PagosVendedores'));
const PagosDirectosPayU = lazy(() => import('./payu/PagosDirectosPayU'));
const PagosDirectosCredibanco = lazy(() => import('./payu/PagosDirectosCredibanco'));
const ZonasListado = lazy(() => import('./zonas/listado'));
const ZonasVariablesDomicilio = lazy(() => import('./zonas/variablesDomicilios'));
const ZonasListadoAdd = lazy(() => import('./zonas/add'));
const ConfiguracionDepartamentos = lazy(() => import('./configuracion/departamentos'));
const ConfiguracionMunicipios = lazy(() => import('./configuracion/municipios'));
const Reportes = lazy(() => import('./clientes/reportes'));
const PagoReportes = lazy(() => import('./clientes/pagoReporte'));
const LinksGeneradosCredibanco = lazy(() => import('./productos/LinksCredibanco'));
const LinksGeneradosCredibancoAdd = lazy(() => import('./productos/LinksGeneradosCredibancoAdd'));
const LinksPedidos =  lazy(() => import('./productos/LinksPedidos'));
const DetallePedido = lazy(() => import('./productos/DetallePedido'));
const DatosUsuario = lazy(() => import('./clientes/Usuario'));


const ReinicioLicencia  = ()=>{
  return <div>RPU</div>
}

class AppRoutes extends Component {

  static contextType = StateConsumer;

  render () {
    //console.log('token: ', Store.get("user").token);
    if ((Store.get("user").token===undefined) &&
        ( window.location.pathname!=='/carrito' &&
          window.location.pathname!=='/pagosDirectosPayU' &&
          window.location.pathname!=='/carritoAdmin' &&
          window.location.pathname!=='/login' &&
          window.location.pathname!=='/register' &&
          window.location.pathname!=='/auth/confirm/user'&&
          window.location.pathname!=='/recover-password'        
          )) {
      Store.set("redirect",window.location.href)
      return (<Redirect to="/login" />)
    }else {                 
      if(queryStringParams.redirect==undefined&&(Store.get("user").token!=undefined)&&(parseInt(Store.get("user").privilegios.tipo_usuario_id)==5) && window.location.pathname =='/'){
          return (<Redirect to="/Cuenta/pedidos" />)
      }

      return (
        <Suspense fallback={<Spinner/>}>
          <Switch>
            {(!this.context.state.user || this.context.state.user.token===undefined)?
                  <>
                    <Route exact path="/pagosDirectosPayU" component={ PagosDirectosPayU } />
                    <Route exact path="/login" component={ Login } />
                    <Route exact path="/recover-password" component={ RecoverPassword } />
                    <Route exact path="/register" component={ Register1 } />
                    <Route exact path="/auth/confirm/user" component={ Confirm } />
                    <Route exact path="/reinicioLicencia" component={ ReinicioLicencia } />
                    <Route exact path="/carrito" component={ Carrito } />
                    <Route exact path="/carritoAdmin" component={ CarritoAdmin } />
                    <Route exact path="/pagosDirectosPayU" component={ PagosDirectosPayU } />
                  </>
                  :
                  <>
                    <Route exact path="/Clientes/Reportes" component={ Reportes } />
                    <Route exact path="/Clientes/PagoReportes*" component={ PagoReportes } />
                    <Route exact path="/Configuracion/Departamentos" component={ ConfiguracionDepartamentos } />
                    <Route exact path="/Configuracion/Municipios" component={ ConfiguracionMunicipios } />
                    <Route exact path="/Zonas/listado" component={ ZonasListado } />
                    <Route exact path="/Zonas/variables-domicilios" component={ ZonasVariablesDomicilio } />
                    <Route exact path="/Zonas/listado/add" component={ ZonasListadoAdd } />
                    <Route exact path="/Configuracion/ComisionPayU" component={ ComisionPayU } />
                    <Route exact path="/pagosDirectosPayU" component={ PagosDirectosPayU } />
                    <Route exact path="/pagosDirectosCredibanco" component={ PagosDirectosCredibanco } />
                    <Route exact path="/Clientes/PagosVendedores" component={ PagosVendedores } />
                    <Route exact path="/generaLink" component={ GeneraLink } />
                    <Route exact path="/detalleCompra" component={ DetalleCompra } />
                    <Route exact path="/pagos" component={ Pago } />
                    <Route exact path="/carrito" component={ Carrito } />
                    <Route exact path="/carritoAdmin" component={ CarritoAdmin } />
                    <Route exact path="/login" component={ Login } />
                    <Route exact path="/dashboard" component={ Dashboard } />                    
                    <Route exact path="/Clientes/Balance*" component={ BalancePedidosRealizados } />
                    <Route exact path="/Clientes/Pedidos" component={ PedidosRealizados } />
                    <Route exact path="/Clientes/Pedidos/send" component={ PedidosRealizadosEnvios } />
                    <Route exact path="/Clientes/Links" component={ LinksGenerados } />
                    <Route exact path="/Clientes/Links/Add" component={ LinksGeneradosAdd } />
                    <Route exact path="/Clientes/LinksPayU" component={ LinksGeneradosPayU } />
                    <Route exact path="/Clientes/LinksCredibanco" component={ LinksGeneradosCredibanco } />
                    <Route exact path="/Clientes/LinksPayU/add" component={ LinksGeneradosPayUAdd } />
                    <Route exact path="/Clientes/LinksCredibanco/add" component={ LinksGeneradosCredibancoAdd } />
                    <Route exact path="/Datos/usuario" component={ DatosUsuario } />
                    <Route exact path="/Cuenta/pedidos" component={ LinksPedidos } />
                    <Route exact path="/Cuenta/detallePedido" component={ DetallePedido } />  
                    <Route exact path="/Cuenta/Wishlist" component={ Wishlist } />
                    <Route path="/Clientes/Pagos" component={ Pagos } />
                    <Route path="/Clientes/Listado*" component={ Listado } />
                    <Route path="/Clientes/TiposUsuarios*" component={ TiposUsuarios } />
                    <Route path="/Configuracion/Productos*" component={ Productos } />
                    <Route path="/Configuracion/Categorias*" component={ Categorias } />
                    <Route path="/Configuracion/Marcas*" component={ Marcas } />
                    <Route path="/Configuracion/Backup*" component={ Backup } />
                    <Route path="/Productos/Precios*" component={ ProductosPrecios } />
                    <Route path="/Productos/Inventario*" component={ ProductosInventario } />
                    <Route path="/basic-ui/buttons" component={ Buttons } />
                    <Route path="/user-pages/login-1" component={ Login } />
                    <Route path="/user-pages/register-1" component={ Register1 } />
                  </>
            }
          </Switch>
        </Suspense>
      );
    }
  }
}

export default AppRoutes;
