import React, { Component } from 'react';
import StateConsumer from '../../helpers/ContextState';

class Footer extends Component {
  static contextType = StateConsumer;
  render () {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2020 <a href="https://fenixpuntonet.com/" target="_blank" rel="noopener noreferrer">Fenixpuntonet</a>. All rights reserved.</span>
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Desarrollado por Fenixpuntonet<i className="mdi mdi-heart text-danger"></i></span>
          </div>
          <a style={{cursor:'pointer'}} onClick={()=>{this.context.cerrarSesion()}}>Cerrar sesión</a>
        </div>
      </footer>
    );
  }
}

export default Footer;
