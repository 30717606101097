import React from 'react';
import './NoLicencia.css';
import {Config} from '../helpers/Index';

const NoLicencia  = (props)  =>{
  return  <>
            <div class="context">
              <div className="container pt-5">
                <div className="row justify-content-center pt-5">
                  <div className="col-6 text-center">
                    <div><img className="img-max-240" src={Config.error} alt={props.state.message}/></div>
                    <h4 className="mt-5">{props.state.message}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="area" >
               <ul class="circles">
                 <li></li>
                 <li></li>
                 <li></li>
                 <li></li>
                 <li></li>
                 <li></li>
                 <li></li>
                 <li></li>
                 <li></li>
                 <li></li>
               </ul>
            </div >
          </>
}

export default NoLicencia
