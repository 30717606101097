import Config from "./Config";
import Store from "./Store";
import socketIOClient from "socket.io-client";
const publicIp    =   require('public-ip');
const internalIp  =   require('internal-ip');
//const Socket      =   socketIOClient(Config.ConfigSocketUrl);

/*SE DEBE PASAR EL EVENT*/
const InputTypeFilter=(event)=>{
  return event.target.value;
}

function isObject(val) {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
}

function compareDates(obj){
  var f1 = new Date(obj.y1, obj.m1, obj.d1);
  var f2 = new Date(obj.y2, obj.m2, obj.d2);
  if (f1>f2) {
    return true;
  }else{
    return false;
  }
}

function Modal(data,context){
  let modal = {
            back:(data.back!==undefined)?data.back:undefined,
            dataForm:(data.dataForm!==undefined)?data.dataForm:undefined,
            status:true,
            title:data.response.title,
            message:data.response.message,
            btnText:data.response.btnText,
            ico:{
                  contentColor:"modal-ico-bg-green",
                  ico:'fas fa-check pl-1',
                },
          }
  context.setState({dialog:modal})
}

// function Loading(context){
//   let modal = {
//             status:true,
//             title:"Loading",
//             message:"Send",
//             btnText:"hide",
//             ico:{
//                   contentColor:"modal-ico-bg-green",
//                   ico:'fas fa-check pl-1',
//                 },
//           }
//   context.setState({dialog:modal})
// }

function segment(num){
  if (num===undefined) {
    num=1
  }
  let pathname      =   window.location.pathname;
  let segment       =   pathname.split('/');
  let last          =   (segment.length - num);
  return segment[last];
}

function resolveAfter2Seconds() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, 2000);
  });
}

async function MyIpPublic(){
  return await publicIp.v4()
};

async function MyIpInternal(){
  return await internalIp.v4()
};

const CutString=(text,wordsToCut)=>{
    // if (wordsToCut===undefined) {
    //   wordsToCut = 20;
    // }
    // var wordsArray = text.split(" ");
    // if(wordsArray.length>wordsToCut){
    //     var strShort = "";
    //     for(i = 0; i < wordsToCut; i++){
    //         strShort += wordsArray[i] + " ";
    //     }
    //     return strShort+"...";
    // }else{
    //     return text;
    // }
};

const FechaHoy = ()  =>{
  /*FECHA DE HOY*/
  let date    =   new Date( );
  let day     =   date.getDate();
      if (day < 10) {
        day = "0"+day;
      }
  let month  =  date.getUTCMonth();
      if (month < 10) {
        month  =  month+1;
        month  =  "0"+month;
      }else {
        month  =  month+1;
      }

  let year   =  date.getUTCFullYear();
  let newDate = year+"-"+month+"-"+day;
  return newDate;
}

const Convertir_base64 = (result)  =>{
  // return new Promise(resolve => {
  //   let base64;
  //   base64 =  FileSystem.readAsStringAsync(  result.uri,{encoding: FileSystem.EncodingType.Base64,});
  //   resolve(base64)
  // });
}

const ObjectToInputs = (data)  =>{
  let _return = {}
  Object.entries(data).map((v,k)=>{
    return _return[v[0]] = v[1]
  })
  return _return;
}

const Get = (modulo,m,objeto,state,loading) =>{
  let headers   =   new Headers();
  let data      =   new FormData();

  Object.entries(objeto).map((v,k) => {
    return data.append (v[0],v[1]);
  })

  data.append ("PUBLIC_KEY", Config.PUBLIC_KEY);

  let cabecera  =   {
                      headers:headers,
                      method: "POST",
                      body: data
                    }
  let url   =       Config.ConfigApirest + "get?modulo="+modulo+"&m="+m+"&formato=json";
  
  fetch(url,cabecera)
      .then(response  =>  response.json()        )
      .then(data      =>  { state(data.response.data);  loading(false) })
      .catch((error)  =>  { console.log(error);  loading(false)  });
}

const FormatNumeric=(num, fix)=>{
    var p = num.toFixed(fix).split(".");
    return p[0].split("").reduceRight(function(acc, num, i, orig) {
        if ("-" === num && 0 === i) {
            return num + acc;
        }
        var pos = orig.length - i - 1
        return  num + (pos && !(pos % 3) ? "." : "") + acc;
    }, "") + (p[1] ? "," + p[1] : "");
}

const PostAsync =  async (modulo,m,objeto,context,callback,loading) =>  {  
  if (loading!==undefined) {
    context.setState({loading:"open"})
  }
  let headers   =   new Headers();
  let data      =   new FormData();

  Object.entries(objeto).map((v,k) => {
    return data.append (v[0],v[1]);
  })

  if (  context!==undefined &&
        context.state!==undefined &&
        context.state.LabelsLang!==undefined &&
        context.state.LabelsLang.lang!==undefined
        ) {
    data.append ("lang",context.state.LabelsLang.lang);
  }else {
    data.append ("lang", Store.get("lang"));
  }

  if (  context!==undefined &&
        context.state!==undefined &&
        context.state.user!==undefined &&
        context.state.user.token!==undefined) {
    data.append ("user", context.state.user.token);
  }
  
  data.append ("method", "post");
  data.append ("PUBLIC_KEY", process.env.REACT_APP_PUBLIC_KEY);
  data.append ("REACT_APP_PRIVATE_KEY", process.env.REACT_APP_PRIVATE_KEY);
  data.append ("BACKEND", process.env.REACT_APP_BACKEND);

  if (Store.get("security")===null) {
    data.append ("PRIVATE_KEY", Config.PRIVATE_KEY);
  }else {
    data.append ("tokens_access",Store.get("security"));
  }

  if (callback!==undefined) {
      data.append ("callback",callback.name);
  }

  let cabecera  =   {
                      headers:headers,
                      method: "POST",
                      body: data
                    }  
  
  try {
    const response    =   await fetch(Config.ConfigApirest + modulo+"/"+m,cabecera);    
    const json        =   await response.json();       
    if (json.response!==undefined && json.response.callback!==undefined) {
      callback.funct(json)
    }
    if(context!==undefined && context!=='' && context!==true){
      context.setState({loading:false})
    }
    // console.log('response: ', json);
    return json;
  }catch (error) {
    //console.log(error);
    return error;
  }
}

const PostAsyncJson =  async (modulo,m,objeto,context,callback) =>  {
  let headers   =   new Headers();
  let data      =   new FormData();
  let lang      =   (Store.get("lang")===undefined || Store.get("lang")==='')?Store.get("lang"):"es";

  Object.entries(objeto).map((v,k) => {
    return data.append (v[0],v[1]);
  })


  data.append ("lang",lang);
  data.append ("method", "post");
  data.append ("PUBLIC_KEY", process.env.REACT_APP_PUBLIC_KEY);
  data.append ("REACT_APP_PRIVATE_KEY", process.env.REACT_APP_PRIVATE_KEY);

  if (Store.get("security")===null) {
    data.append ("PRIVATE_KEY", Config.PRIVATE_KEY);
  }else {
    data.append ("tokens_access",Store.get("security"));
  }

  if (callback!==undefined) {
      data.append ("callback",callback.name);
  }

  let cabecera  =   {
                      headers:headers,
                      method: "POST",
                      body: data,
                      credentials: "same-origin"
                    }

  try {
    const response    =   await fetch(Config.ConfigApirest + modulo+"/"+m,cabecera);
    const json        =   await response.json();
    if (json.response!==undefined && json.response.callback!==undefined) {
      callback.funct(json)
    }
    return json;
  }catch (error) {
    console.log(error);
    return error;
  }
}

const setSession =  (data)=>{
  Store.set("user",data)
  document.location.href  = Config.ConfigAppUrl+'admin'
}

const setTokenStore=(data)=>{
  Store.set("security",data.data);
}

const segments = (last_bool)  =>{
  let pathname      =   window.location.pathname;
  let segment       =   pathname.split('/');
  let last          =   (segment.length) - 1 ;
  let _return       =   ''
  if (last_bool!==undefined && last_bool!==false) {
      segment.map((v,k)=>{
        if (last===k) {
          return _return = v
        }
        return false
      })
      return  _return
  }else{
    return segment
  }
}

const onChangeInput = (e,setState) =>{    
  setState({[e.target.name]:e.target.value})
}


export default {  CutString,
                  FechaHoy,
                  Convertir_base64,
                  Get,
                  InputTypeFilter,
                  setSession,
                  setTokenStore,
                  MyIpPublic,
                  MyIpInternal,
                  PostAsync,
                  resolveAfter2Seconds,
                  //Socket,
                  segment,
                  PostAsyncJson,
                  ObjectToInputs,
                  Modal,
                  segments,
                  isObject,
                  compareDates,
                  onChangeInput,
                  FormatNumeric
                }