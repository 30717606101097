import React, { Component } from 'react';
import StateContext from '../helpers/ContextState';
import { withRouter } from 'react-router-dom';
import './App.scss';
import '../App.css';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import NoLicencia from '../screens/NoLicencia';
import {Config,Labels,Store,Functions} from '../helpers/Index';
import Toast from '../screens/Toast';
import Modal from '../screens/Modal';
import {ToastsStore} from 'react-toasts';
import queryString from 'query-string';

const queryStringParams = queryString.parse(window.location.search);

let token         =   false

let defaultState  = {
                      operadores:{},
                      socket:false,
                      isFullPageLayout:true,
                      reload:false,
                      user:{},
                      confirm:false,
                      LabelsLang:false,
                      ConfigAppUrl:'',
                      wizard:false,
                      loading: false,
                      splash: false,
                      loggedIn: false,
                      QuestionAge: false,
                      dialog:{
                                show:false,
                                title:"Título de la ventana",
                                message:"Prueba de mensaje",
                                callback:false
                      }
                    };

class App extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState
  }

  componentDidMount() {
    /*Functions.Socket.emit('traer_conectados')
    Functions.Socket.on('resetLicense', this.resetLicense.bind(this));
    Functions.Socket.on('set_operadores', this.set_operadores.bind(this));
    Functions.Socket.on('get_mi_id', this.get_mi_id.bind(this));
    Functions.Socket.on('delete_operadores', this.delete_operadores.bind(this));
    Functions.Socket.emit('get_mi_id');*/
    //console.log(queryStringParams.lenguaje)
    if(queryStringParams.lenguaje!==undefined){
      Store.set("lang",queryStringParams.lenguaje)
      if(Store.get("lang")==""){
        Store.set("lang","es")
      }
    }else{
      Store.set("lang","es")
    }
   
    
    this.onRouteChanged();
    
    this.setState({LabelsLang:Labels.traduccion(),user:Store.get("user")})
    let hoy   =   new Date().toLocaleDateString();
    //console.log(Store.get("lang"));
    //Store.set("lang",queryStringParams.lenguaje)
    if (Store.get("lang")!=="es" && Store.get("lang")!=="en") {
         Store.set("lang","es")
    }
   

    if (  (
            Store.get("active").licencia===undefined &&
            Store.get("active").licencia_consulta===undefined
          )
            ||
          (
            Store.get("active").licencia_consulta!==hoy
          )
        ){
      this.active()
    }

    if (Store.get("user").token) {
      this.setState({user:Store.get("user")})
    }
  }

  set_operadores=(token)=>{
    if (Store.get("user").token) {
      Functions.Socket.emit('set_operadores',{user:Store.get("user"),token:token})
    }
    this.setState({socket:token})
  }

  get_mi_id=(data)=>{
    this.setState({socket:data})
    this.set_operadores(data)
    //this.setState({operadores:data})
    // Object.entries(data).map((row,key)=>{
    //   return console.log(row);
    // })
  }

  delete_operadores=()=>{
    if (Store.get("user").token===undefined) {
      Functions.Socket.emit('delete_operadores',{token:this.state.socket,user:{}})
    }
  }

  resetLicense=(data)=>{
    if (data===Store.get("active").licencia) {
      Store.set("active","");
      this.cerrarSesion();
    }else if (Store.get("active").licencia==='' || Store.get("active").licencia===undefined) {
      this.active();
      this.setState({user:false})
      document.location.href='/reinicioLicencia'
    }else {
      //console.log(Store.get("active").licencia);
    }
  }

  active  = ()  =>{
    let contextTemp       =   {  setCEO:this.setCEO,
                                                                  setState:this._setState,
                                                                  state:this.state
                                                                }
    let data              =   {}
        data.PUBLIC_KEY   =   process.env.REACT_APP_PUBLIC_KEY
        data.BACKEND      =   process.env.REACT_APP_BACKEND        
        //Functions.PostAsync(  "Configuracion", "GetActive", data , contextTemp  , {name:"callbackGetActive",funct:this.callbackGetActive});

        /*EN LOS NAVEGADORES MOIVILES TENGO PROBLEMAS CON LAS CONSULTAS A LA LICENCIA*/
        Store.set("active",{
                              "licencia_consulta": "23/9/2020",
                              "licencia": "Licencia_dbaa08da30a121e40e14b9811ac27213",
                              "licencia_vencimiento": "20/08/2021",
                              "y": "2021",
                              "m": "08",
                              "d": "20",
                              "message": "Licencia activa",
                              "error": false,
                              "callback": "callbackGetActive"
                            });
  }

  callbackGetActive=(data)=>{
    //data.response.licencia_consulta="10/2/2020"
    //console.log(data.response);
    Store.set("active",data.response)
    this.setState(data.response)
    if (data.response.licencia==='') {
      let obj = {
        title:data.response.message,
      }
      this.setCEO(obj)
    }
    if (document.location.pathname==='/carrito' && data.response.licencia!=='') {
      document.location.reload();
    }
  }

  setCEO=(obj)=>{
    document.title = Config.Title + ' - ' + obj.title;
    document.querySelector('meta[property="og:type"]').setAttribute( "content", "Prueba");
    document.querySelector('meta[property="og:url"]').setAttribute( "content", "Prueba");
    //document.querySelector('meta[property="og:image"]').setAttribute( "content", "Prueba");
    document.querySelector('meta[property="og:site_name"]').setAttribute( "content",   document.title);
    document.querySelector('meta[property="og:title"]').setAttribute( "content",   document.title);
    document.querySelector('meta[property="og:description"]').setAttribute( "content", "Prueba");
  }

  _setState=(data)=>{
    this.setState(data);
  }

  deleteLogedUser = (token) => {
    Functions.PostAsync(  "Usuarios", "LogoutUser", {"token_user": token} , this.context , {name:"callbackLogoutUser",funct:() => {}});
  }

  cerrarSesion=()=>{
    // let token = Store.get("user").token;
    // this.deleteLogedUser(token);   }    
    Store.set("user","");
    this.setState({user:{}})    
    window.location.reload();
    // document.location.ref='/login'
  }

  render () {

    //console.log(this.state.isFullPageLayout)

    let navbarComponent =   !this.state.isFullPageLayout ? <Navbar Labels={this.state.LabelsLang} /> : '';
    let sidebarComponent =  !this.state.isFullPageLayout ? <Sidebar/> : '';
    let footerComponent =   !this.state.isFullPageLayout ? <Footer/> : '';
    let context         =   {   setCEO:this.setCEO,
                                setState:this._setState,
                                state:this.state,
                                ToastsStore:ToastsStore,
                                cerrarSesion:this.cerrarSesion,
                                delete_operadores:this.delete_operadores
                              }
    /*console.log(navbarComponent); */                        
    if (Store.get("active").licencia) {
      return (
        <>
          <Modal {...context}/>
          <Toast/>
          <StateContext.Provider value={context}>
            <div className="container-scroller">
              { navbarComponent }
              <div className="container-fluid page-body-wrapper full-page-wrapper">
                { sidebarComponent }
                <div className="main-panel">
                  <div className="content-wrapper">
                    <AppRoutes/>
                  </div>
                  { footerComponent }
                </div>
              </div>
            </div>
          </StateContext.Provider>
        </>
      );
    }else{
      return (<>
                <Modal {...context}/>
                <Toast/>
                  <StateContext.Provider value={context}>
                  <NoLicencia Labels={this.state.Labels} state={this.state}/>
                </StateContext.Provider>
              </>
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    if (!Store.get("active").licencia) {
      return false;
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [  '/reinicioLicencia',
                                    '/carrito',
                                    '/carritoAdmin',
                                    '/detalleCompra',
                                    '/login',
                                    '/pagosDirectosPayU',
                                    '/generaLink',
                                    '/register',
                                    '/recover-password',
                                    '/auth/confirm/user',
                                    '/user-pages/login-1',
                                    '/user-pages/login-2',
                                    '/user-pages/register-1',
                                    '/user-pages/register-2',
                                    '/user-pages/lockscreen',
                                    '/error-pages/error-404',
                                    '/error-pages/error-500',
                                    '/general-pages/landing-page'];

    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
    //  console.log(this.props.location.pathname)
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        //console.log(this.state.isFullPageLayout)
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
       
      
      }
    }
  }

}

export default withRouter(App);
