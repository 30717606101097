import React from 'react';

const ConstMenu  = [
  /*MODELO DE ITEM SENCILLO*/
  {
    label:"Dashboard",
    ico:<i className="mdi mdi-television menu-icon"></i>,
    path:"/dashboard",
  }, 
  {
    label:"Clientes",
    ico:<i className="mdi mdi-crosshairs-gps menu-icon"></i>,
    path:"/Clientes",
    collapse:
      {
        /* ESTO ES EL NOMBRE EN EL STATE */
        /* PARA EXPANDIR O NO */
        in:"basicUiMenuOpen",
        items:[
          {
            label:"Listado de clientes",
            path:"/Clientes/Listado",
          },
          {
            label:"Crear y ver Pedidos",
            path:"/Clientes/Links",
          },
          {
            label:"Link de pagos PayU",
            path:"/Clientes/LinksPayU",
          },
          {
            label:"Ventas",
            path:"/Clientes/Pedidos",
          },
          {
            label:"Balance",
            path:"/Clientes/Balance",
          },
          {
            label:"Link pagos por Credibanco",
            path:"/Clientes/LinksCredibanco",
          },
          {
            label:"Pagos Vendedores",
            path:"/Clientes/PagosVendedores",
          },
          {
            label:"Reportes - Informes - Cartera",
            path:"/Clientes/Reportes",
          },
        ]
      }
  },
  {
    label:"Configuración",
    ico:<i className="mdi mdi-crosshairs-gps menu-icon"></i>,
    path:"/Configuracion",
    collapse:
      {
        /* ESTO ES EL NOMBRE EN EL STATE */
        /* PARA EXPANDIR O NO */
        in:"basicUiMenuOpen2",
        items:[
          {
            label:"Departamentos",
            path:"/Configuracion/Departamentos",
          },
          {
            label:"Municipios",
            path:"/Configuracion/Municipios",
          },
          {
            label:"Categorías Productos",
            path:"/Configuracion/Categorias",
          },
          {
            label:"Marcas Asociadas",
            path:"/Configuracion/Marcas",
          },
          {
            label:"Importar Productos Excel",
            path:"/Configuracion/Productos",
          },
          {
            label:"Comisión PayU",
            path:"/Configuracion/ComisionPayU",
          },
          {
            label:"Backup Productos",
            path:"/Configuracion/Backup",
          },          
        ]
      }
  },
  {
    label:"Productos",
    ico:<i className="mdi mdi-crosshairs-gps menu-icon"></i>,
    path:"/Productos",
    collapse:
      {
        /* ESTO ES EL NOMBRE EN EL STATE */
        /* PARA EXPANDIR O NO */
        in:"basicUiMenuOpen3",
        items:[
          {
            label:"Listado y Precios",
            path:"/Productos/Precios",
          },
          {
            label:"Inventario",
            path:"/Productos/Inventario",
          },
        ]
      }
  },
  {
    label:"Zona de atención",
    ico:<i className="mdi mdi-crosshairs-gps menu-icon"></i>,
    path:"/Productos",
    collapse:
      {
        /* ESTO ES EL NOMBRE EN EL STATE */
        /* PARA EXPANDIR O NO */
        in:"basicUiMenuOpen4",
        items:[
          {
            label:"Listado",
            path:"/Zonas/listado",
          },   
          {
            label:"Variables Domicilios",
            path:"/Zonas/variables-domicilios",
          },       
        ]
      }
  },
  {
    label:"Pedidos",
    ico:<i className="mdi mdi-shopping menu-icon"></i>,
    path:"/Cuenta",
    collapse:
      {
        /* ESTO ES EL NOMBRE EN EL STATE */
        /* PARA EXPANDIR O NO */
        in:"basicUiMenuOpen5",
        items:[
          {
            label:"Pedidos",
            path:"/Cuenta/pedidos",
          },         
          {
            label:"Reportes / Informes",
            path:"/Clientes/Reportes",
          },          
        ]
      }
  },
  {
    label:"Lista de deseos",
    // ico:<i className="mdi mdi-television menu-icon"></i>,
    path:"/Cuenta/Wishlist",
  },
  {
    label:"Mis datos",
    ico:<i className="mdi mdi-account menu-icon"></i>,
    path:"/Datos",
    collapse:
      {
        /* ESTO ES EL NOMBRE EN EL STATE */
        /* PARA EXPANDIR O NO */
        in:"basicUiMenuOpen6",
        items:[
          {
            label:"Mi usuario",
            path:"/Datos/usuario",
          },
         
        ]
      }
  },
]

export default ConstMenu
