export default{
  Prefijo:"/pwa",
  ConfigAppUrl : (document.location.hostname==='localhost')?'http://localhost:3020/': (process.env.NODE_ENV==='development')?'https://pwa.satoribelleza.com/':'https://pwa.satoribelleza.com/',
  ConfigSocketUrl : (document.location.hostname==='localhost')?'http://socket.satoribelleza.com:3021/':(process.env.NODE_ENV==='development')?'https://socket.satoribelleza.com:3021/':'https://socket.satoribelleza.com:3021/',
  ConfigNotifications:process.env.REACT_APP_URL_NOTIFICATIONS,
  PayuCheckoutUrl : process.env.REACT_APP_PAYU_URL,
  //ConfigApirest   : (document.location.hostname==='localhost')?'http://localhost/api.satoribelleza.com/ApiRest/':(process.env.NODE_ENV==='development')?'https://api.satoribelleza.com/ApiRest/':'https://api.satoribelleza.com/ApiRest/',
  ConfigApirest   : (document.location.hostname==='localhost')?'https://api.satoribelleza.com/ApiRest/':(process.env.NODE_ENV==='development')?'https://api.satoribelleza.com/ApiRest/':'https://api.satoribelleza.com/ApiRest/',
  Title:'Supralab',
  Alt:'Supralab',
  db:"Supralab",
  logo:require("../assets/images/design/logo.png"),
  logo2:require("../assets/images/Servicio-Pasarela-Pagos-Credibanco-Colombia-4.jpg"),
  error:require("../assets/images/design/error-licencia.gif"),
}

// export default{
//   Prefijo:"/pwa",
//   // ConfigAppUrl : (document.location.hostname==='localhost')?'http://localhost:3020/': (process.env.NODE_ENV==='development')?'https://pwa.satoribelleza.com/':'https://pwa.satoribelleza.com/',
//   ConfigAppUrl : (document.location.hostname==='localhost')?'http://localhost:3000/': (process.env.NODE_ENV==='development')?'https://pwa.satoribelleza.com/':'https://pwa.satoribelleza.com/',      
//   ConfigSocketUrl : (document.location.hostname==='localhost')?'http://socket.satoribelleza.com:3021/':(process.env.NODE_ENV==='development')?'https://socket.satoribelleza.com:3021/':'https://socket.satoribelleza.com:3021/',
//   ConfigNotifications:process.env.REACT_APP_URL_NOTIFICATIONS,
//   PayuCheckoutUrl : process.env.REACT_APP_PAYU_URL,
//   //ConfigApirest   : (document.location.hostname==='localhost')?'http://localhost/api.satoribelleza.com/ApiRest/':(process.env.NODE_ENV==='development')?'https://api.satoribelleza.com/ApiRest/':'https://api.satoribelleza.com/ApiRest/',
//   // ConfigApirest   : (document.location.hostname==='localhost')?'https://api.satoribelleza.com/ApiRest/':(process.env.NODE_ENV==='development')?'https://api.satoribelleza.com/ApiRest/':'https://api.satoribelleza.com/ApiRest/',  
//   // ConfigApirest   : 'https://api.satoribelleza.com/ApiRest/',  
//   ConfigApirest   : 'http://apisatoribelleza.com/ApiRest/',  
//   Title:'Supralab',
//   Alt:'Supralab',
//   db:"Supralab",
//   logo:require("../assets/images/design/logo.png"),
//   logo2:require("../assets/images/Servicio-Pasarela-Pagos-Credibanco-Colombia-4.jpg"),
//   error:require("../assets/images/design/error-licencia.gif"),
// }