import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import {Store} from './helpers/Index';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';


const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

if (Store.get("user").token===undefined) {
  ReactDOM.render(
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CacheBuster>, 
    document.getElementById('root')
  );
}else {
  ReactDOM.render(
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <BrowserRouter >      
          <App />      
      </BrowserRouter>
    </CacheBuster>, 
    document.getElementById('root')
  );
}

serviceWorker.unregister();
