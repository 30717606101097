import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'

const dialog = {
        show:false,
        title:"",
        message:"Prueba de mensaje",
        callback:false
      }

class App extends Component {

  render(){
    return (
            <div className="container">
                <Modal
                      show={this.props.state.dialog.show}
                      onHide={()=>this.props.setState({dialog:dialog})}
                      backdrop="static"
                      keyboard={false}
                      dialogClassName="modal-90w"
                      size={(this.props.state.dialog.size!==undefined && this.props.state.dialog.size!=='')?this.props.state.dialog.size:'md'}
                >
                {
                  (this.props.state.dialog.title)?<Modal.Header closeButton>
                                                    <Modal.Title>{this.props.state.dialog.title}</Modal.Title>
                                                  </Modal.Header>:''
                }
                <Modal.Body>
                  {this.props.state.dialog.message}
                </Modal.Body>
              </Modal>
            </div>
          )
  }

}

export default App
