import Store from "./Store";
const es  = {
  LabelPlaceholder1:"Departamento",
  LabelSave:"Guardar",
  LabelMarcas:"Marcas",
  LabelCategorias:"Categorías",
  LabelPedidos:"Pedidos Realizados",
  LabelLinksVentas:"Links de ventas",
  LabelFecha:"Fecha / Estatus",
  LabelGenerarLinks:"Generar Links de venta",
  labelEmailOptional:"Email (Opcional)",
  labelTelefonoOptional:"Teléfono (Opcional)",
  LabelClientName:"Nombre y apellidos del cliente (Opcional)",
  LabelGeneradorDeLinks:"Generador Links de venta",
  labelEstado:"Estado",
  labelDescripcion:"Descripción",
  labelCedula:"Cédula",
  labelTelefono:"Teléfono",
  labelExtra:"Apt, piso, etc. (opcional)",
  labelDireccion:"Dirección",
  labelPagosYEnvios:"Datos de pagos y envíos",
  labelProductBackup3:"Respaldos",
  labelProductBackup2:"Respaldar produtos",
  labelProductBackup:"Respaldo de produtos",
  labelValorUnitario:"Valor Unitarios",
  labelSubtotal:"Subtotal",
  labelCantidad:"Cantidad",
  labelImagen:"Imagen",
  labelProductosSeleccionados:"Estos son los productos que usted ha seleccionado",
  LabelTotales:"Totales",
  LabelSalidas:"Salidas",
  LabelEntradas:"Entradas",
  LabelPrecios:"Precios",
  LabelMarca:"Marca",
  LabelURL:"Url",
  LabelTags:"Tags",
  LabelPrivilegios:"Privilegios",
  LabelLocked:"Bloqueado",
  LabelUserTypes:"Tipos de usuarios",
  LabelRegistroSave:"Estoy conforme, guardar estos registros",
  LabelRegistroAgrupados:"Registros agrupados",
  LabelRegistroTotales:"Registros totales",
  LabelResumen:"Resumen",
  LabelAccion:"Acción",
  LabelTipoUsuario:"Tipo de usuario",
  LabelPedidosVendedor:"Vendedor asignado",
  LabelPedidosEnEspera:"En espera",
  LabelPedidos:"Pedidos",
  LabelPleaseWait:"Por favor espere",
  LabelReady:"Listo",
  LabelSelectFile:"Seleccione el archivo",
  LabelPrice:"Precio",
  LabelInventory:"Inventario",
  LabelCategory:"Categoría",
  LabelProduct:"Producto",
  LabelImportExcel:"Importar excel",
  LabelProductos:"Productos",
  LabelConfiguracion:"Configuración",
  LabelUsers:"Usuarios",
  LabelFechaDesde:"Fecha desde",
  LabelFechaHasta:"Fecha hasta",
  LabelNuevoProyecto:"Nueva Licencia",
  LabelValidity:"Vigencia",
  LabelLicense:"Licencia",
  LabelDomain:"Dominio",
  LabelUser:"Usuario",
  LabelManage:"Gestionar",
  LabelClients:"Clientes",
  LabelContinue:"Continuar",
  LabelForgotConfirm:"Confirmación de registro de usuarios",
  LabelForgotAA:"¿Ya tienes una cuenta?",
  LabelNames:"Nombres",
  LabelSurnames:"Apellidos",
  lang:"es",
  LabelLoginWelcome1:"¡Hola! empecemos",
  LabelLoginWelcome2:"Inicia sesión para continuar.",
  LabelUsername:"Correo electrónico",
  LabelPassword1:"Contraseña",
  LabelPassword:"Contraseña para poder entrar a nuestra plataforma",
  LabelSignIn:"Registrarse",
  LabelLogIn:"Iniciar Sesión",
  LabelForgotPassword:"¿Se te olvidó tu contraseña?",
  LabelForgotDA:"¿No tienes una cuenta?",
  LabelCreate:"Crear",
  LabelNewHere:"¿Nuevo aquí?",
  LabelRecoverPassword:"Recupere su contraseña",
  LabelNewHere2:"Registrarse es fácil. Solo toma unos pocos pasos",
  LabelOrde:"Debe completar todos los campos para continuar con el pedido",
  Labelwithout:"¨Pagar, no soy usuario registrado ",
  Labelregistereduser:"Pagar, soy usuario registrado",
  LabelNameAndSurname:"nombre y apellido",
  LabelDocumenttype:"Tipo de docuemento",
  LabelDocumentnumber:"Numero de documento",
  LebelSelect:"Seleccione",
  LabelNext:"Continuar",
  labelCedulacidadania:'Cédula de ciudadanía',
  labelCedulaextranjeria:'Cédula de extranjería',
  labelPasaporte:'Pasaporte',
  labelPermiso:'Permiso especial',
  LabelTipopresona:'Seleccione tipo de Persona',
  LabelNatural:'Natural',
  Labeljuridica:'Juridíca',
  LabelShopping:'Canasta de compra',
  LabelShipmentinformation:'Información de envio',
  LabelPaymentconfirmation:'Confirmación de pago',
  LabelUserinformation:'Información del usuario',
  LabelAddressinformation:'Ingrese la dirección de envío',
  LabelIndications:'Observaciones',
  Labelstate:'Departamento',
  Labelcity:'Municipio',
  Labelzone:'Zona',
  LabelAddrestree:'Direccion, calle, N° casa, Apto, piso etc',
  escriba:'Escriba aqui...',
  LabelEditinformation:'Editar información',
  LabelViewsummary:'Ver resumen',
  Userinformationandaddress:'Informacion del usuario y direccion',
  Summaryoftotaltopay:'Resumen del total a pagar',
  Next:'Siguiente',
  Back:'Volver',
  Completeorder:'Completar pedido',
  LabelVolvercarrito:'Volver al carrito',
  LabelsPedido:'Pedidos',
  Labelseguir:'Seguir Comprando',
  Completepay:'Completar pago',
  Labelvolvercompra:'Volver a comprar',
  Ordered:'Ordenado',
  Shipped:'Empacado',
  Ontheway:'Despachado',
  Delivered:'Entregado',
}

const en  = {
  LabelPlaceholder1:"Department",
  LabelSave:"Save",
  LabelMarcas:"Trademarks",
  LabelCategorias:"Categories",
  LabelPedidos:"Orders Placed",
  LabelLinksVentas:"Sales Links",
  LabelFecha:"Date / status",
  LabelGenerarLinks:"Generate Sales Links",
  labelEmailOptional:"Email (Optional)",
  labelTelefonoOptional:"Telephone (optional)",
  LabelClientName:"Name and surname of the client (Opcional)",
  LabelGeneradorDeLinks:"Sales Links Generator",
  labelEstado:"Status",
  labelDescripcion:"Description",
  labelCedula:"ID",
  labelTelefono:"Phone",
  labelExtra:"Apt, piso, etc. (optional)",
  labelDireccion:"Direction",
  labelPagosYEnvios:"Payment and shipping data",
  labelProductBackup3:"Backups",
  labelProductBackup2:"Support products",
  labelProductBackup:"Product support",
  labelValorUnitario:"Unit value",
  labelSubtotal:"Subtotal",
  labelCantidad:"Quantity",
  labelImagen:"Image",
  labelProductosSeleccionados:"These are the products you have selected",
  LabelTotales:"Totals",
  LabelSalidas:"Outs",
  LabelEntradas:"In",
  LabelPrecios:"Prices",
  LabelMarca:"Brand",
  LabelURL:"Url",
  LabelTags:"Tags",
  LabelPrivilegios:"Privileges",
  LabelLocked:"Locked",
  LabelUserTypes:"User types",
  LabelRegistroSave:"I agree, save these records",
  LabelRegistroAgrupados:"Grouped records",
  LabelRegistroTotales:"Total records",
  LabelResumen:"Summary",
  LabelAccion:"Action",
  LabelTipoUsuario:"Type of user",
  LabelPedidosVendedor:"Assigned seller",
  LabelPedidosEnEspera:"On hold",
  LabelPedidos:"Orders",
  LabelPleaseWait:"Please Wait",
  LabelReady:"Ready",
  LabelSelectFile:"SelectFile",
  LabelPrice:"Precio",
  LabelInventory:"Inventory",
  LabelCategory:"Category",
  LabelProduct:"Product",
  LabelImportExcel:"Excel import",
  LabelProductos:"Products",
  LabelConfiguracion:"Configuration",
  LabelUsers:"Users",
  LabelFechaDesde:"Date from",
  LabelFechaHasta:"Date to",
  LabelNuevoProyecto:"New License",
  LabelValidity:"Validity",
  LabelLicense:"License",
  LabelDomain:"Domain",
  LabelUser:"User",
  LabelManage:"Manage",
  LabelClients:"Clients",
  LabelContinue:"Continue",
  LabelForgotConfirm:"User registration confirmation",
  LabelForgotAA:"Already have an account?",
  LabelNames:"Names",
  LabelSurnames:"Surnames",
  lang:"en",
  LabelLoginWelcome1:"Hello! let's get started",
  LabelLoginWelcome2:"Sign in to continue.",
  LabelUsername:"Email",
  LabelPassword1:"password",
  LabelPassword:"Add a password so you can enter our platform",
  LabelSignIn:"Sing In",
  LabelLogIn:"Log in",
  LabelForgotPassword:"Forgot password?",
  LabelForgotDA:"Don't have an account?",
  LabelCreate:"Create",
  LabelNewHere:"New here?",
  LabelRecoverPassword:"Recover your password",
  LabelNewHere2:"Signing up is easy. It only takes a few steps",
  LabelOrde:"You must complete all the fields to continue with the order",
  Labelwithout:"Pay without registering",
  Labelregistereduser:"Pay, I am a registered user",
  LabelNameAndSurname:"name and surname",
  LabelDocumenttype:"Document type",
  LabelDocumentnumber:"Document number",
  LebelSelect:"Select",
  LabelNext:"Next",
  labelCedulacidadania:'National identity card',
  labelCedulaextranjeria:'Foreigner identification card',
  labelPasaporte:'Passport identity card',
  labelPermiso:'Special permit card',
  LabelTipopresona:'Select your legal entity',
  LabelNatural:'Natural',
  Labeljuridica:'Legal',
  LabelShopping:'Shopping basket',
  LabelShipmentinformation:'Shipment information',
  LabelPaymentconfirmation:'Payment confirmation',
  LabelUserinformation:'User information',
  LabelAddressinformation:'Shipping address information',
  LabelIndications:'Indications',
  Labelstate:'State',
  Labelcity:'City',
  Labelzone:'Zone',
  LabelAddrestree:'Address, street, house number, apt, floor etc',
  escriba:'Type here...',
  LabelEditinformation:'Edit information',
  LabelViewsummary:'View summary',
  Userinformationandaddress:'User information and address',
  Summaryoftotaltopay:'Summary of total to pay',
  Next:'next',
  Back:'Back',
  Completeorder:'Complete order',
  LabelVolvercarrito: 'Back to cart',
  LabelsPedido:'Orders',
  Labelseguir:'Keep buying',
  Completepay:'Complete pay',
  Labelvolvercompra:'Keep buying',
  Ordered:'Ordered',
  Shipped:'Shipped',
  Ontheway:'On the way',
  Delivered:'Delivered',
 
}

function traduccion(){
  let lang  = Store.get("lang")
  //console.log(lang);
  if(lang==="es"){
    return es;
  }else{
    return en;
  }
}

function traduccion2(lang){
  if(lang==="es"){
    return es;
  }else{
    return en;
  }
}

export  default{
  traduccion2,
  traduccion,
  es,
  en,
}
